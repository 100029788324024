<template>
  <div class="excursion-view">
    <div class="excursion-view__wrapper">

    </div>
  </div>
</template>

<script>
export default {
  name: 'ExcursionView',
};
</script>
